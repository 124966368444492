@import '../../../node_modules/ngx-smart-modal/styles/ngx-smart-modal.scss';
// Override .overlay class for ngx-smart-modal
.overlay {
	--bs-modal-zindex: 1055;
	--bs-modal-width: 500px;
	--bs-modal-padding: 0.5rem;
	--bs-modal-margin: 0.5rem;
	--bs-modal-color: null;
	--bs-modal-bg: #fff;
	--bs-modal-border-color: rgba(#000, 0.175);
	--bs-modal-border-width: 1px;
	--bs-modal-border-radius: 0.5rem;
	--bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(#000, 0.075);
	--bs-modal-inner-border-radius: subtract(0.5rem, 1px);
	--bs-modal-header-padding-x: 1rem;
	--bs-modal-header-padding-y: 1rem;
	--bs-modal-header-padding: 1rem 1rem; // Todo in v6: Split this padding into x and y
	--bs-modal-header-border-color: #dee2e6;
	--bs-modal-header-border-width: 1px;
	--bs-modal-title-line-height: 1.5;
	--bs-modal-footer-gap: 0.5rem;
	--bs-modal-footer-bg: null;
	--bs-modal-footer-border-color: #dee2e6;
	--bs-modal-footer-border-width: 1px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
	transition: background-color 500ms;
	background-color: transparent;
	z-index: 999;

	&.transparent {
		background-color: transparent;
	}
}
